export const categories = [
  {value: "hot drink", label: "☕ Hot Drink"},
  {value: "cold drink", label: "🥤 Cold Drink"},
  {value: "food", label: "🍔 Food"},
  {value: "medicine", label: "💊 Medicine"},
  {value: "toilet paper", label: "🧻 Toilet Paper"},
  {value: "entertainment", label: "📺 Entertainment"},
  {value: "clothes", label: "👕 Clothes"},
  {value: "hospital", label: "🏥 Hospital"},
  {value: "other", label: "Other"},
];
